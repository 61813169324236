export default [{
        header: 'MENU',
        action: 'read',
        resource: 'ACL',
    },

    {
        title: 'Candidate',
        route: 'apps-list',
        icon: 'UsersIcon',
        action: 'read',
        resource: 'ACL',
    },

    {
        title: 'Todo',
        route: 'apps-todo',
        icon: 'CheckSquareIcon',
        action: 'read',
        resource: 'ACL',
    },
    {
        title: 'Calendar',
        route: 'apps-calendar',
        icon: 'CalendarIcon',
        action: 'read',
        resource: 'ACL',
    },

    {
        title: 'Companies',
        route: 'companies-list',
        icon: 'GridIcon',
        action: 'read',
        resource: 'ACL',
    },

    {
        title: 'Positions',
        route: 'job-list',
        icon: 'CopyIcon',
        action: 'read',
        resource: 'ACL',
    },

    {
        title: 'Users',
        route: 'apps-users-list',
        icon: 'UserIcon',
    },
    {
        title: 'Settings',
        icon: 'SettingsIcon',
        children: [{
                title: 'Skills',
                route: 'apps-skill',
            },

        ],
    },


]